import { palette } from './palette';
import background from 'assets/images/faq-banner-img.webp';
import testimonialBg from 'assets/images/testimonials-banner-img.webp';

export const homeStyle = {
  /* Login - Styles */
  '.login-root': {
    '& .hero-shaped__wrapper': {
      '& .hero-shaped__left-side': {
        flex: '0 0 60%',
        maxWidth: '60%',

        '@media(min-width: 900px) and (max-width: 1200px)': {
          flex: '0 0 50%',
          maxWidth: '50%',
        },

        '& .hero-shaped__image': {
          width: '60vw',

          '@media(min-width: 900px) and (max-width: 1200px)': {
            width: '50vw',
          },
        },
      },
    },
  },

  '.header-wrapper': {
    background: `${palette.background.primaryLight} !important`,
    padding: '8px 16px',
    '& .MuiToolbar-root': {
      minHeight: '72px',
    }
  },

  '.foreversmile-container': {
    '& .normal-text': {
      fontWeight: 300,
    },

    '& .savings-hero-section, .checkout-hero-section, .plan-selection-section,.faq-hero-section, .dashboard-hero-section, .find-provider-section, & .terms-hero-section, & .privacy-hero-section, & .hipaa-hero-section':
      {
        backgroundColor: palette.background.header,
        marginTop: '60px'
      },

    '& .subscription-wrapper, .checkout-outer-container, .addonsWrapper, .plan-selection-container':
      {
        '& .MuiSwitch-thumb': {
          background: palette.text.highlight,
        },
        '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
          backgroundColor: palette.secondary.main,
        },
        '& .MuiSwitch-track': {
          border: 'none',
          background: palette.secondary.main,
        },
      },

      '& .locale-container': {
        background: palette.background.ribbon,
      },

    /* App main - styles */
    '& .app-main-content': {
      paddingTop: 0,
    },

    /* Hero Section - Styles */
    '& .home-hero-variant-2': {
      marginTop: '80px'
    },
    '& .home-hero-wrapper': {
      backgroundColor: palette.text.highlight,

      '& .hero-background': {
        objectFit: 'cover',
        '@media screen and (max-width: 900px)': {
          height: '340px',
        },
      },
      '& .hero-section': {
        display: 'flex',
        alignItems: 'center',
        maxHeight: '600px',
        '& .section-header': {
          '@media screen and (min-width: 768px)': {
            marginTop: '60px',
          },
        },
      },
      '& .home-hero-pretitle': {
        position: 'relative',
        color: palette.primary.main,
        fontWeight: '500',
        fontSize: '30px',
      },
      '& .home-hero-title': {
        fontWeight: '400',
        fontSize: '3rem',
        lineHeight: '72px',
        letterSpacing: 2,
      },
      '& .home-hero-subtitle': {
        maxWidth: 510,
      },
    },

    /* Benefits - Styles */
    '& .definition-variant-2': {
      '& .benefits-wrapper': {
        '& section-header__title': {
          weight: '600',
        },
      },
      '& #definition-section': {
        '& .benefits-wrapper': {
          padding: '0 24px',
        },
      },
    },

    /* Pricing & Benefits / Subscription - Styles */
    '& .subscription-wrapper': {
      '& .subscription-bg': {
        height: '100%',
        background: palette.text.primary,
      },
      '& .section-header__title, .section-header__subtitle, .summary-text, .summary-subtext':
        {
          color: `${palette.text.highlight} !important`,
        },
      '& #pricing-benefit-section': {
        '& .planCard': {
          border: 'none',
          '& .bold': {
            color: palette.text.primary,
          },
          '& .customCardTitle, .perksTitle': {
            fontFamily: 'Dela Gothic One'
          },
          '&:hover': {
            border: `1px solid ${palette.text.primary}`,
            color: palette.text.primary,
            background: palette.text.highlight,
          },
          '& .highlight-divider': {
            backgroundColor: palette.text.disabled,
          },
          '& .MuiButton-containedSecondary': {
            '&:hover': {
              opacity: 1,
            },
          },
          '& .planCardDescription': {
            height: 'calc(100% - 24px)',
          },
        },
        '& .subscription-section': {
          objectFit: 'cover',
          '& .section-header': {
            maxWidth: 720,

            '@media(min-width: 900px)': {
              margin: '0px auto 32px',
            },
          },
          '& .plans-loader': {
            color: palette.text.highlight,
          },
        },
        '& .savings-summary-link': {
          color: palette.text.highlight,
          textDecoration: 'underline',
          cursor: 'pointer',
        },
      },
    },

    /* How it works - Styles */
    '& .howitworks-section': {
      background: palette.background.main,

      '& .howitworks-wrapper': {
        padding: 20,
      },

      '& .howitworks-wrapper-left': {
        padding: 20,
      },

      '& .makeStyles-root-54': {
        padding: 0,
      },

      '& .how-cover-image': {
        backgroundSize: 'cover',
      },

      '& .step-counter': {
        borderRadius: '16%',
        backgroundColor: palette.background.secondaryLight,
        color: palette.text.primary,
      },

      '& .step-started-button': {
        '& .right-icon': {
          color: palette.text.highlight,
        },
      },

      '& .working-steps-container:not(:last-child)': {
        paddingBottom: 30,
      },

      '@media screen and (max-width: 900px)': {
        '& .howitworks-wrapper-left': {
          padding: '5px',
        },
      },

      '@media(min-width: 900px)': {
        '& .howitworks-wrapper-left': {
          paddingLeft: '100px',
        },
      },
    },

    /* Testimonial - Styles */
    '& .testimonial-wrapper': {
      backgroundImage: `url(${testimonialBg})`,
      backgroundColor: palette.background.secondaryLight,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',

      '@media(min-width: 900px)': {
        padding: 72,
      },

      '& .testimonial-name, & .testimonial-location, & .testimonial-message': {
        color: palette.text.primary,
      },

      '& .testimonialSection': {
        padding: '40px 32px',

        '@media(min-width: 900px)': {
          padding: '40px 16px',
        },
      },
    },

    /* Savings Summary */
    '& .savings-wrapper': {
      '& .section-header': {
        '& .section-header__title': {
          color: palette.text.primary,
        },
        '& .section-header__subtitle': {
          color: palette.text.primary,
        },
      },
      '& .service-procedure-table': {
        border: `1px solid ${palette.secondary.main} !important`,
        '& .savings-topheader': {
          color: `${palette.text.highlight} !important`,

          '&.savings-column-2': {
            '&::before': {
              content: '"Member Pays"',
            },
            '& div': {
              display: 'none',
            },
          },
        },

        '& .savings-header': {
          color: palette.text.primary,
        },

        '& .savings-subheader': {
          backgroundColor: palette.background.main,
          color: palette.text.primary,
          fontWeight: 600,
        },
      },

      '& .savings-contact-card': {
        backgroundColor: palette.background.header,
      },
    },

    /* Static hero banner */
    '& .home-static-hero-wrapper': {
      '& .hero-static-bg': {
        backgroundColor: palette.text.highlight,
      },
    },

    /* Book Appointment */
    '& .morning-image, & .noon-image, & .evening-image': {
      fill: `${palette.primary.main} !important`,
    },

    /* Create Subscriber - Checkout flow container */
    '& .checkout-flow-container': {
      '& .provider-details': {
        border: `1px solid ${palette.text.border}`,
      },
      '& .addon-bg': {
        background: palette.background.secondaryLight,
      },
      '& .MuiSwitch-thumb': {
        background: palette.text.highlight,
      },
      '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
        backgroundColor: palette.primary.main,
        opacity: 1,
      },
      '& .MuiSwitch-track': {
        background: palette.secondary.main,
      },
    },

    /* Create Subscriber - Choose plan */
    '& .plan-selection-container': {
      '& .plans-wrapper': {
        '& .planCard': {
          border: `1px solid ${palette.text.primary}`,
          '&:hover': {
            color: palette.text.primary,
            background: palette.text.highlight,
          },
          '& .highlight-divider': {
            backgroundColor: palette.text.highlight,
          },
          '& .planCardDescription': {
            height: 'calc(100% - 24px)',
          },
        },
      },
    },

    /* FAQ */
    '& .faq-hero-section': {
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',

      '& .hero-header-title, .hero-header-subtitle': {
        margin: '0',
        textAlign: 'left',
      },
      '& .header-container': {
        justifyContent: 'left',
        margin: '0',
      },
      '@media screen and (max-width: 900px)': {
        '& .faq-hero-internal': {
          marginTop: '10px',
          padding: '64px 24px !important'
        }
      },
    },
    '& .faq-contact-section': {
      background: palette.background.header,
      width: '100%',
      margin: 'unset',
      maxWidth: 'unset',
      '& .contact-img-wrapper': {
        width: '64px',
        maxWidth: '64px',
      },
    },

    /* Create Subscriber - Checkout */
    '& .checkout-outer-container': {
      '& .payment-title-root': {
        marginTop: 0,
      },
      '& .promo-spinner': {
        backgroundColor: 'unset',
      },
      '& .termsText': {
        fontFamily: 'none',
        fontWeight: 'bold'
      }
    },

    /* Contact Card - FAQ, Success Page */
    '& .faq-contact-wrapper': {
      background: palette.background.footer,
    },
    '& .mail-link': {
      color: palette.text.primary,
    },

    /* Dashboard Page - Appointment Header */
    '& .MuiTableRow-root .MuiTableCell-head': {
      color: palette.text.highlight,
    },

    /* Stepper - styles */
    '& .customStepperRoot': {
      maxWidth: '598px',
      '& .MuiStepConnector-lineHorizontal': {
        borderColor: palette.primary.main,
      },
    },
  },
};