import YesIcon from 'assets/images/yes-icon.svg';
import QuoteLeft from 'assets/images/quote-left.svg';
import SuccessImage from 'assets/images/create-success.svg';
import CancelRenewalImage from 'assets/images/cancel-renewal-v2.svg';
import CanceSubslImage from 'assets/images/sad-smiley.svg';
import ThumbsupImage from 'assets/images/thumbsup-smiley.svg';
import Logo from 'assets/images/logo.svg';
import FooterLogo from 'assets/images/logo-footer.svg';
import Theme from 'theme';

const {
  REACT_APP_GROUP_ID: groupId,
  REACT_APP_AWS_REGION: region,
  REACT_APP_COGNITO_POOL_ID: userPoolId,
  REACT_APP_COGNITO_WEB_CLIENT_ID: userPoolWebClientId,
  REACT_APP_CLIENT_HEADER: clientHeader = groupId,
  REACT_APP_APPPOINTMENT_LINK: bookAppointmentLink,
} = process.env;

const locations = [
  { key: 'LOC1', name: 'Lutz'},
  { key: 'LOC2', name: 'Connerton'},
  { key: 'LOC3', name: 'Valrico (Lithia Pinecrest Rd.)'},
  { key: 'LOC4', name: 'Sebring'},
  { key: 'LOC5', name: 'Sarasota (Laurel Ridge Rd.)'},
  { key: 'LOC6', name: 'Winter Springs (Willa Springs Rd.)'},
  { key: 'LOC7', name: 'Winter Springs (SR 434)'},
  { key: 'LOC8', name: 'Vero Beach'},
  { key: 'LOC9', name: 'Sebastian Dental Spa'},
  { key: 'LOC10', name: 'Lake Wales'},
  { key: 'LOC11', name: 'Davenport'},
  { key: 'LOC12', name: 'Lakeland'},
  { key: 'LOC13', name: 'H.A.B. Dental Boca Raton'},
  { key: 'LOC14', name: 'H.A.B Dental Boynton Beach'},
  { key: 'LOC15', name: 'Gerard Valentini, DDS'},
  { key: 'LOC16', name: 'The Villages (Mulberry Grove)'},
  { key: 'LOC17', name: 'Summerfield (Villages Community)'},
  { key: 'LOC18', name: 'Ocala (Silver Springs)'},
  { key: 'LOC19', name: 'Ocala'},
  { key: 'LOC20', name: 'Mount Dora'},
  { key: 'LOC21', name: 'Leesburg'},
  { key: 'LOC22', name: 'Lake Mary'},
  { key: 'LOC23', name: 'Lady Lake'},
  { key: 'LOC24', name: 'Inverness'},
  { key: 'LOC25', name: 'Gainesville'},
  { key: 'LOC26', name: 'Spring Hill (Hernando)'},
  { key: 'LOC27', name: 'Spring Hill (Hernando West Publix Plaza)'},
  { key: 'LOC28', name: 'Orlando (Sand Lake)'},
  { key: 'LOC29', name: 'Orlando (Orange Blossom Trail)'},
  { key: 'LOC30', name: 'Orange City'},
  { key: 'LOC31', name: 'Ocoee'},
  { key: 'LOC32', name: 'Clermont'},
  { key: 'LOC33', name: 'St. Petersburg (Central Avenue)'},
  { key: 'LOC34', name: 'St. Petersburg (4th Street North)'},
  { key: 'LOC35', name: 'San Carlos (Fort Myers)'},
  { key: 'LOC36', name: 'Port Charlotte (Toledo Blade)'},
  { key: 'LOC37', name: 'Naples (Naples Park)'},
  { key: 'LOC38', name: 'Eagle Creek Dentistry'},
  { key: 'LOC39', name: 'Cape Coral'},
  { key: 'LOC40', name: 'Arcadia'},
  { key: 'LOC41', name: 'Tarpon Springs'},
  { key: 'LOC42', name: 'Tampa (Westchase)'},
  { key: 'LOC43', name: 'Palm Harbour.'},
  { key: 'LOC44', name: 'Clearwater (E. Bay Drive)'},
  { key: 'LOC45', name: 'Clearwater (Countryside Blvd.)'},
  { key: 'LOC46', name: 'Trinity'},
  { key: 'LOC47', name: 'Tampa (Tampa Palms)'},
  { key: 'LOC48', name: 'Tampa (Downtown'},
  { key: 'LOC49', name: 'Tampa (Carrollwood)'},
  { key: 'LOC50', name: 'Tampa (USF - Fletcher)'},
  { key: 'LOC51', name: 'Riverview'},
  { key: 'LOC52', name: 'New Port Richey (Grand Trinity Plaza)'},
  { key: 'LOC53', name: 'New Port Richey (Golden Acres)'},
  { key: 'LOC54', name: 'Lutz (Osprey Cove)'},
];

const locationApptLinks = locations.map((loc) => {
  const {
    [`REACT_APP_${loc.key}_APPT_LINK`]: locApptLink,
  } = process.env;
  return {
    name: loc.name,
    link: locApptLink,
    target: '_blank',
  };
});

const config = {
  groupId,
  region,
  userPoolId,
  userPoolWebClientId,
  logo: Logo,
  cptCodesIcon: YesIcon,
  portalName: 'foreversmile',
  tokenName: 'foreversmileSubscriberToken',
  globalClass: 'foreversmile-container',
  clientHeader,
  enableHeroButton: true,
  pricingType: 'standard-pricing',
  showAddressSuggestion: true,
  heroLocaleColor: 'secondary',
  splitPlans: true,
  headerProps: {
    enable: true,
  },
  localeProps: {
    enable: true,
    color: 'secondary',
    localesList: [
      {
        key: 'en',
        value: 'English',
      },
      {
        key: 'es',
        value: 'Spanish',
      },
    ],
  },
  homeTemplateProps: {
    heroSection: {
      variant: 2,
      preTitleClasses: 'home-hero-pretitle',
      titleClasses: 'home-hero-title',
      subTitleClasses: 'home-hero-subtitle',
      primaryButtonProps: {
        to: '/find-a-provider',
      },
      secondaryButtonProps: {
        to: '/login',
        color: 'primary',
      },
      background: '/images/home-images/home-banner-img.webp',
      backgroundMobile: '/images/home-images/home-banner-img.webp',
    },
    benefitsSection: {
      variant: 2,
      columns: 3,
      alignTitle: 'left',
      alignCard: 'left',
    },
    subscriptionSection: {
      cardVariant: 3,
      tabVariant: 2,
      disableGroupSavings: true,
      carouselResponsiveItems: {
        desktop: 3,
      },
      showAddons: false,
      primaryButtonProps: {
        color: 'primary',
      },
    },
    howItWorksSection: {
      variant: 1,
      heroImage: '/images/home-images/how-it-works.webp',
    },
    testimonialSection: {
      variant: 3,
      quoteImage: QuoteLeft,
      messageType: 'h6',
      gridSpacing: {
        xSmall: 12,
        medium: 12,
      },
      carouselResponsiveItem: {
        superLarge: 1,
        large: 1,
      },
    },
    staticHeroSection: {
      enable: true,
      variant: 1,
      primaryButtonProps: {
        color: 'primary',
      },
    },
  },
  checkoutSection: {},
  findProviderSection: {
    locationWrapper: 7,
    mapWrapper: 5,
  },
  choosePlanSection: {
    sectionSubtitle: '',
    locationLogo: Logo,
    cardVariant: 2,
    tabVariant: 2,
    planCardProps: {
      buttonProps: {
        normalColor: 'primary',
        highlightedColor: 'primary',
        normalVariant: 'contained',
        highlightedVariant: 'outlined',
      },
    },
    staticHeroSection: {
      enable: true,
      variant: 1,
    },
  },
  subscriptionSuccess: {
    successImage: SuccessImage,
    enableBookAppointment: true,
    bookAppointmentProps: {
      link: bookAppointmentLink,
      target: '_blank',
      locationList: locationApptLinks,
    },
  },
  savingsSummary: {
    enableFooterbar: true,
    showCategoryHeaders: true,
    showPlanDiscHeader: false,
  },
  faqSection: {},
  dashboardProps: {
    showBenefitsUsage: true,
    cancelRenewal: {
      image: CancelRenewalImage,
    },
    cancelSubscription: {
      image: CanceSubslImage,
    },
    reactivateRenewal: {
      image: ThumbsupImage,
    },
  },
  bookAppointment: {},
  footer: {
    enable: true,
    logo: FooterLogo,
  },
  loginPage: {
    bannerImage: './images/login.webp',
  },
  theme: Theme,
};

export default config;
