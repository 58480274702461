export const palette = {
  alternate: {
    main: '#F9F6FF',
    light: '#F9F8F8',
    dark: '#E5EAF4',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  type: 'light',
  primary: {
    main: '#E75204',
    light: '#fcede5'
  },
  secondary: {
    main: '#004A87',
    contrastText: '#0A5285',
    light: '#D9EDF7'
  },
  text: {
    primary: '#004A87',
    secondary: '#3D4956',
    alternate: '#23B0E7',
    disabled: '#CCC',
    highlight: '#FFF',
    success: '#1CA179',
    border: '#E5EAF4',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    main: '#FFFFFF',
    paper: '#FFF',
    default: '#FFF',
    header: '#EEF6FA',
    footer: '#004A87',
    level2: '#f7f8fb',
    level1: '#FFF',
    tableHead: '#004A87',
    tableSubhead: '#EEF6FA',
    tableBorder: '#7D808880',
    tableAlternate: '#FFFFFF',
    alternate: '#E5EAF4',
    success: '#DBEFDC',
    error: '#FFEAEA',
    primary: '#E75204',
    secondary: '#F8D859',
    light: '#F8FBFE',
    secondaryLight: 'rgba(238, 246, 250, 0.6)',
    primaryLight: '#D9EDF7',
    ribbon: '#bbe6fb'
  },
  verticalBar: {
    used: '#E75204',
    unused: '#fcede5',
    unlimited: '#C0C0C0',
  }
};
